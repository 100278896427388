<div style="margin-top: 2em !important;" class="container-fluid p-0 my-2">
  <form class="w-100">
    <div class="row mx-0 w-100">
      <div class="col-xl-1"></div>

      <div class="col-6 col-xl-5 w-100">
        <div class="form-group">
          <label for="projectName">
            {{ "Nome progetto" | translate }}</label>
          <input type="text" class="form-control" id="projectName" name="projectName" [(ngModel)]="wsProject.projectname" />
        </div>
      </div>
    </div>

    <div class="row mx-0 w-100">
      <div class="col-xl-1"></div>

      <div class="col-6 col-xl-5 w-100">
        <div class="form-group">
          <label for="link">
            {{ "Link WSDL" | translate }}</label>
          <input type="text" class="form-control" id="link" name="link" [(ngModel)]="wsProject.wsdlendpoint"/>
        </div>
      </div>

      <div class="col-6 col-xl-5 w-100">
        <div class="form-group">
          <label for="requestName">
            {{ "Nome chiamata" | translate }}</label>
          <input type="text" class="form-control" id="requestName" name="requestName" [(ngModel)]="wsProject.wscallname"/>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let parameter of parametersControlsAsArray; let i = index;">
      <form [formGroup]="parameter" class="row mx-0 w-100">
        <div class="col-xl-1"></div>

        <div class="col-6 col-xl-5 w-100">
          <div class="form-group">
            <label *ngIf="i === 0" for="parameterName">
              {{ "Parametri" | translate }}</label>
            <input type="text" formControlName="name" class="form-control" id="parameterName" name="parameterName"
              placeholder="{{ 'Inserisci il nome del parametro' | translate }}" required/>
          </div>
        </div>

        <div class="col-6 col-xl-5 w-100">
          <div [ngStyle]="{'margin-top': i === 0 ? '7px' : '-24px'}" class="form-group">
            <label for="parameterValue"></label>
            <input type="text" formControlName="value" class="form-control" id="parameterValue" name="ParameterValue"
              placeholder="{{ 'Inserisci il valore del parametro' | translate }}" required/>
          </div>
        </div>

        <div *ngIf="i > 0" class="col-xl-1">
          <button
              class="btn btn-secondary"
              style="background-color: red;"
              (click)="removeParameter(i)"
            >
              {{ "Elimina" | translate }}
            </button>
        </div>
      </form>
    </ng-container>

    <div class="row mx-0 w-100 add-parameter">
      <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
        <button class="btn btn-primary mb-4 w-100" (click)="addParameter(parametersArray)">
            + {{ "Aggiungi parametro" | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="row mx-0 w-100">
    <div class="col-xl-1"></div>

    <div class="form-group col-5">
      <label>{{ "Tipo risposta" | translate }}</label>
      <div>
        <select id="responseType" class="form-control" [(ngModel)]="wsProject.responsetype">
          <option *ngFor="let element of responseTypes" [ngValue]="element.name">
            {{ element.name | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <ng-container *ngIf="wsProject.responsetype && wsProject.responsetype !== ''">
    <!-- TODO da implementare -->
    <!-- <ng-container *ngIf="wsProject.responsetype === 'Tabella'">
      <div class="row mx-0 w-100"></div>
    </ng-container> -->

    <ng-container *ngIf="wsProject.responsetype === 'Parametro' || wsProject.responsetype === 'Parameter'">
      <div class="row mx-0 w-100">
        <div class="col-xl-1"></div>

        <div class="col-6 col-xl-5 w-100">
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="fieldToMap"/>
          </div>
        </div>

        <div class="col-6 col-xl-5 w-100">
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="fieldForSelect"/>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-xl-1"></div>
    
        <div class="form-group col-5">
          <label>{{ "Risposta" | translate }}</label>
          <div>
            <select id="responseType" class="form-control" [(ngModel)]="fieldToMapValue" (change)="onResponseSelectChange()">
              <option *ngFor="let object of objectsList" [ngValue]="object['a:'+fieldToMap+'Field']">
                {{ object['a:'+fieldForSelect+'Field'] }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row mx-0 w-100 add-parameter">
      <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
        <button class="btn btn-primary mb-4 w-100" [disabled]="!isWsProjectSaved()" (click)="generateWsCall(wsProjectId, false, '1')">
          {{ "Genera chiamata" | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <!-- SOTTOCHIAMATA -->
  <ng-container *ngIf="wsProject.wssubcall">
    <hr>
    <div class="row mx-0 w-100">
      <div class="col-xl-1"></div>

      <div class="col-6 col-xl-5 w-100">
        <div class="form-group">
          <label for="link">
            {{ "Link WSDL" | translate }}</label>
          <input type="text" class="form-control" id="link" name="link" [(ngModel)]="wsProject.wssubcall.wsdlendpoint"/>
        </div>
      </div>

      <div class="col-6 col-xl-5 w-100">
        <div class="form-group">
          <label for="requestName">
            {{ "Nome chiamata" | translate }}</label>
          <input type="text" class="form-control" id="requestName" name="requestName" [(ngModel)]="wsProject.wssubcall.wscallname"/>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let subcallParameter of subcallParametersControlsAsArray; let i = index;">
      <form [formGroup]="subcallParameter" class="row mx-0 w-100">
        <div class="col-xl-1"></div>
  
        <div class="col-6 col-xl-5 w-100">
          <div class="form-group">
            <label *ngIf="i === 0" for="parameterName">
              {{ "Parametri" | translate }}</label>
            <input type="text"(input)="getInputValue(i)" formControlName="name" class="form-control" id="parameterName" name="parameterName"
              placeholder="{{ 'Inserisci il nome del parametro' | translate }}" required/>
          </div>
        </div>
  
        <div class="col-6 col-xl-5 w-100">
          <div [ngStyle]="{'margin-top': i === 0 ? '7px' : '-24px'}" class="form-group">
            <label for="parameterValue"></label>
            <input type="text" formControlName="value" class="form-control" id="parameterValue" name="ParameterValue"
              placeholder="{{ 'Inserisci il valore del parametro' | translate }}" required/>
          </div>
        </div>
  
        <div *ngIf="i > 0" class="col-xl-1">
          <button
              class="btn btn-secondary"
              style="background-color: red;"
              (click)="removeParameter(i)"
            >
              {{ "Elimina" | translate }}
            </button>
        </div>
      </form>
    </ng-container>

    <div class="row mx-0 w-100 add-parameter">
      <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
        <button class="btn btn-primary mb-4 w-100" (click)="addParameter(subcallParametersArray)">
            + {{ "Aggiungi parametro" | translate }}
        </button>
      </div>
    </div>

    <div class="row mx-0 w-100">
      <div class="col-xl-1"></div>
  
      <div class="form-group col-5">
        <label>{{ "Tipo risposta" | translate }}</label>
        <div>
          <select id="responseType" class="form-control" [(ngModel)]="wsProject.wssubcall.responsetype">
            <option *ngFor="let element of responseTypes" [ngValue]="element.name">
              {{ element.name | translate }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <ng-container *ngIf="wsProject.wssubcall.responsetype && wsProject.wssubcall.responsetype !== ''">
      <ng-container *ngIf="wsProject.wssubcall.responsetype === 'Tabella' || wsProject.wssubcall.responsetype === 'Table'">
        <div class="row mx-0 w-100">
          <div class="col-xl-1"></div>

          <div class="col-2 col-xl-2 w-100 form-group form-check" style="margin-left: 1em;">
            <div class="form-group">
              <input
                type="checkbox"
                class="form-check-input"
                id="enumeration"
                name="enumeration"
                required
                [(ngModel)]="wsProject.wssubcall.responsetablecolrank"
              />
              <label class="form-check-label" for="enumeration">
                {{ "Numerazione" | translate }}
              </label>
            </div>
          </div>
        </div>

        <div class="row mx-0 w-100 overflow-scroll">
          <div class="col-xl-1"></div>

          <ng-container *ngFor="let column of subcallTableControlsArray; let i = index;">
              <div [formGroup]="column" class="col-2 col-xl-2 w-100">
                <div class="form-group">
                  <input type="text" formControlName="name" class="form-control"/>
                </div>
                <div class="form-group">
                  <input type="text" formControlName="value" class="form-control"/>
                </div>
                <div style="text-align: center; margin-bottom: 1.5em;">
                  <button
                    class="btn btn-secondary"
                    style="background-color: red;"
                    (click)="deleteColumn(subcallTableControlsArray, i)"
                  >
                    {{ "Elimina" | translate }}
                  </button>
                </div>
              </div>
          </ng-container>
        </div>

        <div class="row mx-0 w-100 add-parameter" style="margin-top: 0.5em;">
          <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
            <button class="btn btn-primary mb-4 w-100" (click)="addParameter(subcallTableArray)">
                + {{ "Aggiungi colonna" | translate }}
            </button>
          </div>
        </div>

        <div class="row mx-0 w-100">
          <div class="col-xl-1"></div>
  
          <div class="col-6 col-xl-5 w-100">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="tableFieldToMap"/>
            </div>
          </div>
  
          <div class="col-6 col-xl-5 w-100">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="tableFieldForSelect"/>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row mx-0 w-100">
        <div class="col-xl-1"></div>
    
        <div class="form-group col-5">
          <label>{{ "Risposta" | translate }}</label>
          <div>
            <select id="responseType" class="form-control" [(ngModel)]="wsProject.wssubcall.responsevaluetableselect">
              <option *ngFor="let object of subcallResponse" [ngValue]="object['a:'+tableFieldToMap+'Field']">
                {{ object['a:'+tableFieldForSelect+'Field'] }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mx-0 w-100 add-parameter">
        <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
          <button class="btn btn-primary mb-4 w-100" [disabled]="!isWsProjectSaved()" (click)="generateWsCall(wsProjectId, true)">
            {{ "Genera sotto chiamata" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="modal-footer">
    <button *ngIf="!wsProject.wssubcall" data-dismiss="modal" type="button" class="btn btn-primary ml-2 pl-4 pr-4" (click)="addSubCall()">
      + {{ "Aggiungi sottochiamata" | translate }}
    </button>

    <button data-dismiss="modal" type="button" class="btn btn-primary ml-2 pl-4 pr-4" [disabled]="!isFormValid()" (click)="saveWsProject(true)">
      {{ "Duplica" | translate }}
    </button>

    <button data-dismiss="modal" type="button" class="btn btn-primary ml-2 pl-4 pr-4" [disabled]="!wsProject.projectname || !isFormValid()" (click)="saveWsProject()">
      {{ "Salva" | translate }}
    </button>
  
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="goToWsProjectsList()">
      {{ "Annulla" | translate }}
    </button>
  
    <div class="col-xl-1"></div>
  </div>
</div>