<div class="container-fluid my-2">
    <h3 class="color-theme font-weight-bold mb-4">
        Web Service
    </h3>
</div>

<div class="row mx-0">
    <div class="col-12 my-2">
        <div class="row mx-0 w-100">
            <div class="form-group col-1 p-0">
                <div>
                    <label for="search">{{ "Cerca" | translate }}:</label>
                    <input id="search" [(ngModel)]="searchValue" />
                </div>
            </div>

            <div class="col-7 col-sm-7 col-ml-7 col-xl-9"></div>

            <ng-container *ngIf="isAdmin">
                <div class="col-4 col-sm-4 col-ml-4 col-xl-2 p-0">
                    <button class="btn btn-primary mb-4 w-100" [routerLink]="['/addWsProject']">
                        + {{ "Aggiungi progetto WS" | translate }}
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="row mx-0">
            <div class="card w-100">
                <div class="card-body" style="overflow-y: auto">
                    <table class="table" (contextmenu)="onrightClick($event)" oncontextmenu="return false;">
                        <thead>
                            <tr>
                                <th scope="col">{{ "Nome" | translate }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr oncontextmenu="return false;" *ngFor="let wsProject of wsProjectsList | filter : searchValue"
                                (contextmenu)="onrightClick($event, wsProject, true); $event.stopPropagation()" style="cursor: pointer">
                                <td class="border-bottom" [routerLink]="['/addWsProject/', wsProject.id]" style="border-left: 1px solid rgba(0, 0, 0, 0.125);">
                                    {{ wsProject.projectname }}
                                </td>
                                <td class="border-bottom" style="border-right: 1px solid rgba(0, 0, 0, 0.125); padding: 0; width: 10%;">
                                    <div style="display: flex; flex-direction: row; justify-content: flex-end; width: 100%;">
                                        <div class="modifyImg" [routerLink]="['/addWsProject/', wsProject.id]">
                                            <img src="assets/img/Pittogramma modifica.png" style="width: 30px" alt="" />
                                        </div>
                                        <div *ngIf="isAdmin && wsProject.isdeletable" data-toggle="modal" data-target="#delConfirmModal" class="deleteImg" (click)="deleteWsProject(wsProject.id)">
                                            <img src="assets/img/Pittogramma elimina - cestino.png" style="width: 30px" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>