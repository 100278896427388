import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WsProject } from 'src/app/models/ws-project/ws-project';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { WsProjectsService } from 'src/app/services/web-services/ws-projects.service';
import { generateId } from 'src/app/models/notification/notification';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-ws-project',
  templateUrl: './add-ws-project.component.html',
  styleUrls: ['./add-ws-project.component.scss']
})
export class AddWsProjectComponent implements OnInit, OnDestroy {
  private sub: any;
  wsProject = new WsProject();
  wsProjectId: string;
  projectName: string;
  wsdlEndpoint: string;
  requestName: string;
  responseTypes = [
    { name: 'Tabella', value: 0 },
    { name: 'Parametro', value: 1 }
  ];
  selectedResponseType: string;
  objectsList = [];
  fieldToMap: string;
  fieldForSelect: string;
  fieldToMapValue: any;
  tableFieldToMap: string;
  tableFieldForSelect: string;
  form: FormGroup;
  subcallForm: FormGroup;
  subcallTableForm: FormGroup;
  isCallGenerated: boolean;
  subcallResponse = [];

  constructor(
    private readonly wsProjectsService: WsProjectsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {
    this.form = this.initializeForm();
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.wsProjectId = params['wsProjectId'];

      if (this.wsProjectId) {
        this.wsProjectsService.getWsProjectById(this.wsProjectId).subscribe(res => {
          this.wsProject = res;
          
          this.buildFormData();
        }, err => {
          console.log(err);
        });
      } else {
        if (sessionStorage.getItem('wsProjectCopy')) {
          this.wsProject = JSON.parse(sessionStorage.getItem('wsProjectCopy'));
          
          this.buildFormData();

          sessionStorage.removeItem('wsProjectCopy');

        } else {
          let newGroup = new FormGroup({
            name: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required])
          });
          this.parametersArray.push(newGroup);

        }
      }
    });
  }

  buildFormData() {
    this.populateArrayFromObject(this.wsProject.parameters, this.parametersArray);
    Object.keys(this.wsProject.responseparameter).forEach(key => {
      Object.keys(this.wsProject.responseparameter[key]).forEach(subKey => {
        this.fieldToMap = subKey;
        this.fieldForSelect = this.wsProject.responseparameter[key][subKey];
      });
    });

    if (this.wsProject.responsevalueparameter) {
      this.fieldToMapValue = this.wsProject.responsevalueparameter;
    }

    if (this.wsProject.wssubcall) {
      this.subcallForm = this.initializeForm();
      this.subcallTableForm = this.initializeForm();
      this.populateArrayFromObject(this.wsProject.wssubcall.parameters, this.subcallParametersArray);
    }

    if (this.wsProject.wssubcall && this.wsProject.wssubcall.responsetable) {
      this.populateArrayFromObject(this.wsProject.wssubcall.responsetable, this.subcallTableArray);
    }

    if (this.wsProject.wssubcall && this.wsProject.wssubcall.responsetableselect) {
      Object.keys(this.wsProject.wssubcall.responsetableselect).forEach(key => {
        this.tableFieldToMap = key;
        this.tableFieldForSelect = this.wsProject.wssubcall.responsetableselect[key];
      });
    }

    if (this.wsProject.id) {
      this.generateWsCall(this.wsProject.id, false, '1');
    }
  }

  initializeForm(): FormGroup {
    let form = new FormGroup({
      Parameters: new FormArray([])
    });

    return form;
  }

  addParameter(parametersArray: FormArray): void {
    const newParameter = new FormGroup({
      name: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required])
    });
    parametersArray.push(newParameter);
  }

  get parametersArray(): FormArray {
    return this.form.get('Parameters') as FormArray;
  }

  get parametersControlsAsArray(): FormGroup[] {
    return this.parametersArray.controls as FormGroup[];
  }

  get subcallParametersArray(): FormArray {
    return this.subcallForm.get('Parameters') as FormArray;
  }

  get subcallParametersControlsAsArray(): FormGroup[] {
    return this.subcallParametersArray.controls as FormGroup[];
  }

  get subcallTableArray(): FormArray {
    return this.subcallTableForm.get('Parameters') as FormArray;
  }

  get subcallTableControlsArray(): FormGroup[] {
    return this.subcallTableArray.controls as FormGroup[];
  }

  removeParameter(index: number): void {
    this.parametersArray.removeAt(index);
  }

  isFormValid(): boolean {
    return (this.wsProject.wsdlendpoint && this.wsProject.wsdlendpoint !== '')
      && (this.wsProject.wscallname && this.wsProject.wscallname !== '')
      && this.isParametersListValid()
      && this.areResponseSettingsValid();
  }

  isSubcallFormValid(): boolean {
    return (this.wsProject.wssubcall.wsdlendpoint && this.wsProject.wssubcall.wsdlendpoint !== '')
      && (this.wsProject.wssubcall.wscallname && this.wsProject.wssubcall.wscallname !== '')
      && this.isSubcallFormValid()
      && this.areSubcallResponseSettingsValid();
  }

  isParametersListValid(): boolean {
    return this.form.valid;
  }

  isSubcallPArametersListValid(): boolean {
    return this.subcallForm.valid;
  }

  isWsProjectSaved(): boolean {
    return this.wsProjectId && this.wsProjectId !== '';
  }

  areResponseSettingsValid(): boolean {
    if (this.wsProject.responsetype === 'Parametro' || this.wsProject.responsetype === 'Parameter') {
      return (this.fieldToMap && this.fieldToMap !== '')
        && (this.fieldForSelect && this.fieldForSelect !== '');
    }
  }

  areSubcallResponseSettingsValid(): boolean {
    if (this.wsProject.responsetype === 'Tabella' || this.wsProject.responsetype === 'Table') {
      return this.subcallTableForm.valid 
        && (this.tableFieldToMap && this.tableFieldToMap !== '')
        && (this.tableFieldForSelect && this.tableFieldForSelect !== '');
    }
  }

  saveWsProject(isDuplicate?: boolean): void {
    if (this.isFormValid()) {
      this.updatewsProjectParameters();

      if (!isDuplicate) {
        if (!this.wsProjectId) {
          this.wsProjectsService.saveWsProject(this.wsProject).subscribe(res => {
            const notification = this.createNotification('Completato', 'Salvataggio avvenuto con successo');
            this.notificationService.addNotification(notification);
  
            this.goToWsProjectsList();
          }, err => {
            console.log(err);
          });
        } else {
          this.wsProjectsService.editWsProject(this.wsProject).subscribe(res => {
            const notification = this.createNotification('Completato', 'Modifica avvenuta con successo');
            this.notificationService.addNotification(notification);
  
            this.goToWsProjectsList();
          }, err => {
            console.log(err);
          });
        }
      } else {
        this.duplicateWsProject();
      }
    }
  }

  updatewsProjectParameters() {
    this.wsProject.account = localStorage.getItem('HechRomeoAccount');
    this.wsProject.parameters = {};
    for (let index = 0; index < this.parametersArray.controls.length; index++) {
      const parameter = this.form.get('Parameters').get(index.toString()).value;
      this.wsProject.parameters[parameter.name] = parameter.value;
    }

    if (this.wsProject.responsetype === 'Parametro' || this.wsProject.responsetype === 'Parameter') {
      this.wsProject.responseparameter = {
        [this.fieldToMap]: {
          [this.fieldToMap]: this.fieldForSelect
        }
      };
      this.wsProject.responsevalueparameter = this.fieldToMapValue;
    }

    if (this.wsProject.wssubcall) {
      this.wsProject.wssubcall.parameters = {};
      for (let index = 0; index < this.subcallParametersArray.controls.length; index++) {
        const parameter = this.subcallForm.get('Parameters').get(index.toString()).value;
        this.wsProject.wssubcall.parameters[parameter.name] = parameter.value;
      }

      if (this.wsProject.wssubcall.responsetype === 'Tabella' || this.wsProject.wssubcall.responsetype === 'Table') {
        this.wsProject.wssubcall.responsetable = {};
        for (let index = 0; index < this.subcallTableArray.controls.length; index++) {
          const parameter = this.subcallTableForm.get('Parameters').get(index.toString()).value;
          this.wsProject.wssubcall.responsetable[parameter.name] = parameter.value;
        }
        this.wsProject.wssubcall.responsetableselect = {
          [this.tableFieldToMap]: this.tableFieldForSelect
        };
      }
    }

    if (this.wsProject.responseJson) {
      this.wsProject.responseJson = JSON.stringify(this.wsProject.responseJson);
    }
  }

  goToWsProjectsList() {
    this.router.navigate(['webServices']);
  }

  addSubCall() {
    this.wsProject.wssubcall = new WsProject();
    this.wsProject.wssubcall.wsdlendpoint = this.wsProject.wsdlendpoint;
    this.subcallForm = this.initializeForm();
    this.subcallTableForm = this.initializeForm();
    let newGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required])
    });
    this.subcallParametersArray.push(newGroup);
    this.subcallTableArray.push(newGroup);
  }

  populateArrayFromObject(object: any, arrayToPopulate: FormArray) {
    let array = [];
    Object.keys(object).forEach(key => {
      array.push({
        name: key,
        value: object[key]
      })
    });

    array.forEach(item => {
      let newGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        value: new FormControl('', [Validators.required])
      });
      newGroup.controls['name'].setValue(item.name);
      newGroup.controls['value'].setValue(item.value);
      arrayToPopulate.push(newGroup);
    });
  }

  generateWsCall(wsProjectId: string, isSubcall: boolean, level?: string) {
    if (this.isFormValid()) {
      this.updatewsProjectParameters();

      if (!isSubcall) {
        if (!this.wsProject.wssubcall) {
          delete this.wsProject.wssubcall;
        }

        this.wsProjectsService.getSpecificWsCall(this.wsProject.wsdlendpoint, this.wsProject.wscallname, this.wsProject.parameters).subscribe(res => {
          this.isCallGenerated = true;

          let callResponse = res['s:Envelope']['s:Body'];
          this.getResponseObject(callResponse, this.wsProject.wscallname, this.wsProject);
          
          if (Array.isArray(this.wsProject.responseJson)) {
            this.objectsList = this.wsProject.responseJson;
            this.objectsList.unshift({});
            this.onResponseSelectChange();
          } else {
            this.objectsList.push(this.wsProject.responseJson);
            this.onResponseSelectChange();
          }

          const notification = this.createNotification('Completato', 'Chiamata generata con successo');
          this.notificationService.addNotification(notification);
        }, err => {
          console.log(err);
        });
      } else {
        this.wsProjectsService.getSpecificWsCall(this.wsProject.wssubcall.wsdlendpoint, this.wsProject.wssubcall.wscallname, this.wsProject.wssubcall.parameters).subscribe(res => {
          res = res;
          let tmp = res['s:Envelope']['s:Body']['GetClassificheResponse']['GetClassificheResult']['a:classificheField']['a:Classifica'];
          if (Array.isArray(tmp)) {
            this.subcallResponse = tmp;
            this.subcallResponse.unshift({});
          } else {
            this.subcallResponse.push(tmp);
          }

          const notification = this.createNotification('Completato', 'Sotto chiamata generata con successo');
          this.notificationService.addNotification(notification);
        }, err => {
          console.log(err);
        });
      }
      
    }
  }

  getResponseObject(response: any, wsCallName: string, wsProject: any) {
    let tmp = response;

    for (let key of Object.keys(tmp)) {
      if (key.includes(wsCallName)) {
        tmp = tmp[key];
        this.getResponseObject(tmp, wsCallName, wsProject);
        break;
      } else {
        if (!key.includes('error') && key.includes('Field')) {
          tmp = tmp[key];

          if (Object.keys(tmp).length === 1) {
            tmp = tmp[Object.keys(tmp)[0]];
            wsProject.responseJson = tmp;
          }
        }
      }
    }
  }

  onResponseSelectChange(value?: any) {
    if (this.subcallForm) {
      const index = this.subcallParametersControlsAsArray.findIndex(control => control.value['name'] === this.fieldToMap);

      if (index !== -1) {
        this.getInputValue(index);
      }
    }
  }

  getInputValue(index) {
    if (this.subcallForm.get('Parameters').get(index.toString()).value['name'] === this.fieldToMap) {
      this.subcallForm.get('Parameters').get(index.toString()).get('value').setValue(this.fieldToMapValue);
    }
  }

  createNotification(title: string, body: string) {
    const notification = {
      id: generateId(),
      image: undefined,
      title,
      body
    };

    return notification;
  }

  deleteColumn(controlsArray: FormGroup[], index: number) {
    controlsArray.splice(index, 1);
  }

  duplicateWsProject() {
    let wsProjectCopy = this.wsProject;
    wsProjectCopy.id = '';
    wsProjectCopy.projectname = '';

    sessionStorage.setItem('wsProjectCopy', JSON.stringify(wsProjectCopy));
    this.router.navigate(['addWsProject']);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
